"use client";

import { Button, Text, Y } from "@reframe.so/blocks";
import { ServerError } from "@src/app/errors";
import { redirect } from "next/navigation";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  if (ServerError.isUnauthenticated(error)) {
    return redirect("/sign-in");
  }

  return (
    <html>
      <body>
        <Y>
          <Text>{error.message}</Text>
          <Text>
            If this problem persists, please contact support at
            support@retune.so
          </Text>
          <Button onClick={() => reset()}>Try again</Button>
        </Y>
      </body>
    </html>
  );
}
